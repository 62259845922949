<template>
  <div
    class="bg-center bg-no-repeat bg-cover"
    style="background-image: url('/images/main-bg.jpg')"
  >
    <div class="container md:relative">
      <div class="md:flex md:items-center">
        <div class="py-20 md:z-30 md:py-32 xl:py-40 md:w-2/3 lg:w-1/2">
          <!-- <h1
            class="text-xl font-medium leading-tight text-gray-700 lg:text-2xl"
            data-aos="fade-up"
            data-aos-delay="600"
          >
            INSTANT REDUCTION <br />
            OF PAIN USING
          </h1> -->
          <h1
            class="py-3 text-4xl font-bold xl:text-6xl lg:text-5xl text-md-blue"
            data-aos="fade-up"
            data-aos-delay="1000"
          >
            Are You Suffering From Any Back Pain Or Knee Pain?
          </h1>
          <h2 class="text-lg italic text-gray-700">
            We Use Latest American Technology To Instantly Reduce Pain
          </h2>
          <div class="mx-auto md:flex md:flex-wrap">
            <div class="w-64 md:w-64 md:px-1">
              <a
                id="cta-wa-banner"
                href="https://wa.me/601128978627"
                class="block py-2 mt-2 text-sm text-center text-white uppercase transition duration-300 ease-in rounded-lg bg-lite-blue hover:bg-sm-blue"
              >
                Get A Quick Consultation</a
              >
            </div>
            <div class="w-64 md:w-40 lg:w-44 md:px-1">
              <a
                id="cta-btn-banner"
                href="tel:+601128978627"
                class="block py-2 mt-2 text-sm text-center text-white transition duration-300 ease-in rounded-lg bg-lite-blue hover:bg-sm-blue"
                ><i class="pr-2 fas fa-mobile-alt"></i>011-28978627</a
              >
            </div>
            <!-- <div class="w-64 md:w-1/2 lg:w-44 md:px-1">
              <a
                id="cta-wa-banner"
                href="https://wa.me/601128978627"
                class="block py-2 mt-2 text-sm text-center text-white transition duration-300 ease-in rounded-lg bg-lite-blue hover:bg-sm-blue"
                ><i class="pr-2 fab fa-whatsapp"></i>WHATSAPP US</a
              >
            </div> -->
          </div>
        </div>
        <div
          data-aos="fade-left"
          data-aos-delay="1000"
          class="absolute hidden w-full h-full bg-no-repeat bg-contain custom md:block md:bg-right"
          style="background-image: url('/images/pain.png')"
        >
          <!-- <img
              src="/images/pain.png"
              alt="Back Pain"
              class="hidden md:block"
            /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
@media (min-width:1023px){
  .custom{
    left: 10% !important;
  }
}
@media (min-width:728px){
  .custom{
    left: 34%;
  }
}
</style>